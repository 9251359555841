import { initBrowserClientForWeb } from "utils/SentryInitialize";
var WebSentryIntegration = /** @class */ (function () {
    function WebSentryIntegration() {
        this.sentryScope = initBrowserClientForWeb(WebCoreUIConfig.sentry.overrides.webSentryIntegration.envSuffix, WebCoreUIConfig.sentry.overrides.webSentryIntegration.sampleRate, WebCoreUIConfig.sentry.overrides.webSentryIntegration.tracesSampleRate);
    }
    WebSentryIntegration.getInstance = function () {
        if (!WebSentryIntegration.instance) {
            WebSentryIntegration.instance = new WebSentryIntegration();
        }
        return WebSentryIntegration.instance;
    };
    WebSentryIntegration.prototype.captureException = function (error, options) {
        if (options === void 0) { options = {}; }
        this.sentryScope.captureException(error, {
            captureContext: options
        });
    };
    WebSentryIntegration.prototype.captureMessage = function (message, options) {
        if (options === void 0) { options = {}; }
        this.sentryScope.captureMessage(message, options.level, {
            captureContext: {
                user: options.user,
                tags: options.tags,
                extra: options.extra
            }
        });
    };
    return WebSentryIntegration;
}());
export { WebSentryIntegration };
export var getSentryInstance = function () {
    return WebSentryIntegration.getInstance();
};
