import { CanPlayFromCurriculum } from '../../services/student-api';
import { getCardsCompletedToday } from "../../services/parent-api";
import { canPlayPostParams, getFreemiumAbTestAssignedSegment, isFreeCardsLeftToPlay, sendErrorToSentry } from "../web-helper";
import DefaultPlayable from "./DefaultPlayable";
import DRSPlayable from "./DRSPlayable";
import FreemiumNoBlockerPlayable from './FreemiumNoBlockerPlayable';
import { hasAccessToTeacherFreemiumNoBlocker } from '../feature_mapping';

export const canPlayHelperUtility = async (params, playableData) => {
    const canPlay = canPlayByDefault(params);

    if (!canPlay) {
      let ab_test_segment;
      if(hasAccessToTeacherFreemiumNoBlocker()) {
        ab_test_segment = 'FreemiumNoBlocker';
      }
      else {
        const {parentFreemiumAbTestAssignedSegment, teacherFreemiumAbTestAssignedSegment} = getFreemiumAbTestAssignedSegment() || {}
        ab_test_segment = parentFreemiumAbTestAssignedSegment || teacherFreemiumAbTestAssignedSegment;
      }
      let canUserPlay = false;
      switch(ab_test_segment){
        case 'FreemiumNoBlocker':
          canUserPlay = new FreemiumNoBlockerPlayable(params).canPlay()
          return canUserPlay;
        case 'Topics_Out_On_DRS':
          canUserPlay = await new DRSPlayable(params).canPlay(playableData)
          return canUserPlay ;
        case 'DRSWithLiveClasses':
          canUserPlay = await new DRSPlayable(params).canPlay(playableData)
          return canUserPlay ;
        default:
          canUserPlay = await new DefaultPlayable(params).canPlay(playableData)
          return canUserPlay;
      }
    }
    else{
      return canPlay;
    }
  }
  
  const canPlayByDefault = ({
    currentCourse,
    hasElaAccess,
    hasMathAccess,
    is_classroom_student,
    classroom_selected_mode,
    klass_enrolled_courses,
  }) =>{
    let can_play = false;
    let parentHasCurrentSubjectAccess;

    if(!currentCourse?.subject_code){
      //not subject dependent
      parentHasCurrentSubjectAccess = hasMathAccess || hasElaAccess;
    }
    else
      parentHasCurrentSubjectAccess = currentCourse?.subject_code == 'math' ? hasMathAccess : hasElaAccess;
  
    if (is_classroom_student && classroom_selected_mode === 'school') {
      // school hours
      //If not subject dependent, then return true
      // let isKlassEnrolledForCurrentSubject = !currentCourse?.subject_code ? true : klass_enrolled_courses?.includes(currentCourse?.subject_code);
      // can_play = isKlassEnrolledForCurrentSubject || parentHasCurrentSubjectAccess;
      return true;
    } 
    else {
      // B2C student & PTL
      can_play = parentHasCurrentSubjectAccess ;
    }
  
    return can_play;
  }
  
  export const canPlayFromCourseStatusInfo = ({
    freeCardAccess,
    canPlayFreeCardsFromAnywhere,
    freemium_enabled,
    cardsCompletedToday,
    free_card_limit
  }, playableData) => {
    freeCardAccess = freeCardAccess || (
      canPlayFreeCardsFromAnywhere &&
      freemium_enabled &&
      isFreeCardsLeftToPlay(cardsCompletedToday, free_card_limit)
    );
    let noOfFreePlayableLeft = free_card_limit - cardsCompletedToday;
    playableData.noOfFreePlayableLeft = noOfFreePlayableLeft;

    return freeCardAccess;
  }
  
  export const canPlayFromExploratoryInfo = async ({
    student,
    freemium_enabled,
    free_card_limit
  }, playableData) => {
  
      try {
        let response = await getCardsCompletedToday({ user_id: student.id });
        const cardsCompletedToday = response?.no_of_playable;
        const freeCardAccess =  
          freemium_enabled &&
          isFreeCardsLeftToPlay(cardsCompletedToday, free_card_limit)
          let noOfFreePlayableLeft = free_card_limit - cardsCompletedToday;
          playableData.noOfFreePlayableLeft = noOfFreePlayableLeft;
          
          return freeCardAccess;
      }
      catch (err) {
        //send error to sentry
        console.error(err);
        return false;
      }
  }
  
  export const canPlayFromCurriculum = async ({student}, playableData) =>{
    try {
      const params = canPlayPostParams();
      let response = await CanPlayFromCurriculum(params);
      playableData.noOfFreePlayableLeft = response.remaining_card_count;
      sessionStorage.setItem(`${student?.id}_free_cards_left`, response.remaining_card_count);
      return response.can_play;
    }
    catch(err) {
      console.error(err);
      let errorInfo = {
        extraMsg: 'canPlayFromCurriculum failed',
      };
      sendErrorToSentry(err, errorInfo);
      return false;
    }
  }