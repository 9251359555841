import React from 'react';
import styled, {css} from 'styled-components';

export const breakpoints = {
  xxxl: 1680,
  xxl: 1441,
  xlCommonBreakpoint: 1280,
  xl: 1200,
  lgCommonBreakpoint: 1024,
  lg: 992,
  commonBreakpoint: 897,
  md: 767,
  sm: 480,
  xs: 0,
};
export function getWidthRange(min, max) {
  if (min < 0 || max < 0) {
    console.error('Error in setting minimum maximum');
    return;
  }
  if (!max) {
    return `(min-width: ${min}px)`;
  }
  if (!min) {
    return `(max-width: ${max}px)`;
  }
  return `(min-width: ${min}px) and (max-width: ${max - 1}px)`;
}
export const device = {
  Desktop: `(min-width: ${breakpoints.xl}px)`,
  TabletLandscape: `(min-width: ${breakpoints.lg}px) and (max-width: ${
    breakpoints.xl - 1
  }px)`,
  Tablet: `(min-width: ${breakpoints.md}px) and (max-width: ${
    breakpoints.lg - 1
  }px)`,
  PhoneLandscape: `(min-width: ${breakpoints.sm}px) and (max-width: ${
    breakpoints.md - 1
  }px)`,
  Phone: `(max-width: ${breakpoints.sm - 1}px)`,
  PhonePortrait: `(max-width: ${breakpoints.sm - 1}px) and (orientation: portrait)`,
  // PhoneLandscape: `(max-width: ${breakpoints.sm - 1}px) and (orientation : landscape)`,
  PhoneTablet: `(max-width: ${breakpoints.lg - 1}px)`,
  PhoneTabletLandscape: `(min-width: ${breakpoints.sm}px) and (max-width: ${breakpoints.lg - 1}px) and (orientation: landscape)`,
  Iphone5L: '(min-device-width: 320px) and (max-device-height: 568px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2)',
  Iphone5P: '(min-device-width: 320px) and (max-device-height: 568px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2)',
  Iphone678L: '(min-device-width: 375px) and (max-device-height: 667px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2)',
  Iphone678P: '(min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2)',
  PhoneLargeScreen: `(max-width: 896px) and (orientation : landscape)`,
  PhoneLargeOnly: `(min-width: 768px) and (max-width: 896px) and (orientation : landscape)`,
  PhoneSmallOnly: `(max-width: 600px) and (orientation : landscape)`,

  // Ipads Specific L denoting Landscape P denoting Portrait, other includes both
  // Ipads 1,2, mini, air
  Ipads12: 'only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1)',
  Ipads12L: 'only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1)',
  Ipads12P: 'only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1)',

  // Ipads 3,4 Pro 9.7
  Ipads34: 'only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2)',
  Ipads34L: 'only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2)',
  Ipads34P: 'only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2)',

  // Ipads Pro 10.5
  IpadsPro: 'only screen and (min-device-width: 834px) and (max-device-width: 1112px)and (-webkit-min-device-pixel-ratio: 2)',
  IpadsProL: 'only screen and (min-device-width: 834px) and (max-device-width: 1112px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2)',
  IpadsProP: 'only screen and (min-device-width: 834px) and (max-device-width: 1112px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2)',

  // Ipads Pro 12.9
  IpadProLarge: 'only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2)',
  IpadProLargeL: 'only screen and (min-device-width: 1024px) and (max-device-width: 1136px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2)',
  IpadProLargeP: 'only screen and (min-device-width: 1024px) and (max-device-width: 1136px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2)',

  // Reference -> https://css-tricks.com/snippets/css/media-queries-for-standard-devices/
  // https://responsivedesign.is/develop/browser-feature-support/media-queries-for-common-device-breakpoints/
  // take reference from above link to add more devices if needed
};


const MediaWrapper = styled.div`
  display: none;
  ${props => props.query ? css`
    @media ${props.query} {
      display: block;
    }
  `: ""}
`
const SeoMediaWrapper = styled.div`
  ${props => props.query ? css`
    @media ${props.query} {
      display: block !important;
    }
  `: ""}
`
export const MediaQuery = ({query, children}) => {
  if (!query) {
    return <>{children}</>
  }
  return (
    <MediaWrapper query={query}>
      {children}
    </MediaWrapper>
  )
}

export const SeoHiddenMediaQuery = ({query, children}) => {
  if (!query) {
    return <>{children}</>
  }
  //if need to be hidden for google crawler
  return(
    <SeoMediaWrapper query={query} style={{display:"none"}}>
      {children}
    </SeoMediaWrapper>
  )
}

// export const DesktopBreakpoint = (props) => {
//     return (
//         <MediaQuery query={device.Desktop}>
//             {props.children}
//         </MediaQuery>
//     )
// }

// export const PhoneBreakpoint = (props) => {
//     return (
//         <MediaQuery query={device.Phone}>
//             {props.children}
//         </MediaQuery>
//     )
// }

// export const TabletBreakpoint = (props) => {
//     return (
//         <MediaQuery query={device.Tablet}>
//             {props.children}
//         </MediaQuery>
//     )
// }
// export const PhoneTabletBreakpoint = (props) => {
//     return (
//         <MediaQuery query={device.PhoneTablet}>
//             {props.children}
//         </MediaQuery>
//     )
// }