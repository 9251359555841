var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { camelToTitleCase, generateUUID, getMemoryInfo, getPlayableClickedEventProps } from '../../js/utils/web-helper';
import { GOLD_EVENT_PROPERTIES } from '../constant-data/constants';
export var sendPlayableClickedEvent = function (props, gameMap, item) {
    props['Playable Click Id'] = generateUUID();
    props = __assign(__assign(__assign({}, props), __assign({}, getMemoryInfo())), __assign({}, getPlayableClickedEventProps(gameMap, item)));
    setGoldPropertiesInWindow(props);
    window.trackAnalyticsEvent('Playable Clicked', props);
};
export var setGoldPropertiesInWindow = function (props) {
    var goldProperties = getGoldProperties(props);
    try {
        window.gold_event_properties = goldProperties;
    }
    catch (error) {
        console.error('Error setting gold properties', error);
    }
};
var getGoldProperties = function (props) {
    var updatedProps = {};
    for (var _i = 0, _a = Object.entries(props); _i < _a.length; _i++) {
        var _b = _a[_i], key = _b[0], value = _b[1];
        if (value !== undefined && value !== null) {
            // Convert camelCase key to Title Case with spaces for events via flutter
            var titleCaseKey = camelToTitleCase(key);
            updatedProps[titleCaseKey] = value;
        }
    }
    var result = {};
    GOLD_EVENT_PROPERTIES.forEach(function (key) {
        if (updatedProps[key] !== undefined) {
            result[key] = updatedProps[key];
        }
    });
    return result;
};
