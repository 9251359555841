var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { sendErrorToSentry } from './../../../../app/js/utils/web-helper';
import { BOOKLIBRARY } from '../../constant-data/constants';
export var bookLauncher = function (props) { return __awaiter(void 0, void 0, void 0, function () {
    var client_id, path, options;
    var _a, _b, _c, _d, _e, _f;
    return __generator(this, function (_g) {
        client_id = sessionStorage.getItem('client_id') || '';
        try {
            path = "".concat(BASE_API_GATEWAY_URL, "/playable-service/digital_library/digital_library_playable?user_id=").concat((_a = props.actionData) === null || _a === void 0 ? void 0 : _a.user_id, "&book_code=").concat((_b = props.actionData) === null || _b === void 0 ? void 0 : _b.isbn, "&meta_user_session_id=").concat((_c = props.actionData) === null || _c === void 0 ? void 0 : _c.meta_user_session_id, "&railName=").concat('From My Teacher', "&railRank=").concat(0, "&tileRank=").concat(0, "&pageName=").concat('From My Teacher', "&client_id=").concat(client_id, "&date_tz=").concat(Intl.DateTimeFormat().resolvedOptions().timeZone, "&click_source=").concat('assignment', "&bookAllowed=").concat((_d = props.payloadData) === null || _d === void 0 ? void 0 : _d.bookAllowed, "&hasAccessToBooks=").concat((_e = props.payloadData) === null || _e === void 0 ? void 0 : _e.hasAccessToBooks);
            options = {
                path: path,
                closeHandler: (_f = props.actionParams) === null || _f === void 0 ? void 0 : _f.closeHandler,
                playableName: 'Digital Library',
                multiplayer: true,
                redirectToPurchase: function () {
                    window.location.hash = "/purchase?source=".concat(BOOKLIBRARY, "&trigger=limitBreach");
                },
                setRandomPurchaseUUID: function (purchaseObj) {
                    var purchaseEventObject = {
                        expiryTimeSecs: 3600000,
                        populateTime: Date.now(),
                        data: purchaseObj,
                        key: "purchase_event_data",
                    };
                    var serializedData = JSON.stringify(purchaseEventObject);
                    // Save it to localStorage. Used 'flutter.' as it is fetched in flutter repo too
                    localStorage.setItem('flutter.purchase_event_data', JSON.stringify(serializedData));
                }
            };
            //@ts-ignore
            window.SPWidget.launchContentGame(options);
        }
        catch (e) {
            sendErrorToSentry(e, { from: 'bookLauncher method' });
            console.error(e);
        }
        return [2 /*return*/];
    });
}); };
