var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as MessageType from '../messaging/message_constants';
import * as HashPath from '../messaging/hash_path';
import WidgetSpinner from "../../utils/WidgetLoader";
import GlobalSpinner from "../../utils/GlobalLoader";
import { canShowCatexMigrationScreenValue, deleteCookie, filterExtraParamsForLauncher, getItemFromLocalStorage, handleAddFromChildProfile, playPauseAudio, sendErrorToSentry, sendEventUsingAvo } from "../../utils/web-helper";
import { hasAccessToFreemium } from "../../utils/feature_mapping";
import { getActionHandler } from "../action-handler/ActionHandler";
import { CreateGameRoom, catexMigrationPostAPI } from "../../services/student-api";
import MultiplayerGameLauncher from "../../components/student/multiplayer-games/multiplayer-game-launcher";
import ApplicationContext from "../ApplicationContext";
import { setActiveCourse } from "../../components/freemium/utilityFunctions";
import { READING_SUBJECT_CODE, MATH_SUBJECT_CODE, TAB_CODE } from "../../constant-data/constants";
import { TEACHER_SUPPORT_PAGE_PATH } from "../../routes/route-paths";
import { Store } from "../../stores/student-dashboard";
import { setGoldPropertiesInWindow } from "../../../js/utils/playable-click-helper";
//Singleton message handler class
var MessageHandler = /** @class */ (function () {
    function MessageHandler() {
        var _this = this;
        this.playPauseBgMusic = function () {
            playPauseAudio(true, true, null);
            localStorage.setItem('isPlayableLaunched', 'true');
        };
        this.closeHandlerMPGame = function () { return __awaiter(_this, void 0, void 0, function () {
            var applicationContext;
            return __generator(this, function (_a) {
                applicationContext = new ApplicationContext();
                window.iframeOn = false;
                applicationContext.callableMessages.reloadFlutterBuild();
                return [2 /*return*/];
            });
        }); };
        if (!MessageHandler._instance) {
            MessageHandler._instance = this;
        }
        MessageHandler._instance.bindFlutterMessageEvent();
        return MessageHandler._instance;
    }
    MessageHandler.prototype.bindFlutterMessageEvent = function () {
        var _this = this;
        window.listenFlutterEvent = function (type, payload, eventName) {
            var payloadData = payload;
            try {
                payloadData = payload && JSON.parse(payload);
            }
            catch (err) {
            }
            switch (type) {
                case MessageType.kMessageOpenSettingsPage:
                    return _this.redirectToHash(HashPath.SETTINGS_PATH);
                case MessageType.kMessageOpenChildProfilePage:
                    return _this.redirectToHash(HashPath.CHILD_PROFILE_PATH);
                case MessageType.kMessageOpenReportsPage:
                    return _this.redirectToHash(HashPath.REPORTS_PATH);
                case MessageType.kMessageOpenPurchasePage:
                    return _this.redirectToHash(HashPath.PURCHASE_PATH);
                case MessageType.kMessageOpenAddChildPage:
                    return _this.handleChildAdd();
                case MessageType.kMessageRedirect:
                    return _this.redirectToHash(payloadData);
                case MessageType.kMessageRedirectUsingHref:
                    return _this.redirectUsingHref(payloadData);
                case MessageType.kMessageOpenInTab:
                    return _this.openNewTab(payloadData);
                case MessageType.kMessageBuildLoaded:
                    return _this.onFlutterBuildLoaded();
                case MessageType.kSendMessageString:
                    return _this.sendEvent(eventName, payloadData);
                case MessageType.kMessageMinimizeHeader:
                    return _this.minimizeHeader(payloadData);
                case MessageType.kMessageTileClickHandler:
                    return _this.tileClickHandler(payloadData);
                case MessageType.kMessageClosePopup:
                    return _this.closePopupHandler();
                case MessageType.kMessageBlurHeader:
                    return _this.blurHeaderHandler();
                case MessageType.kMessageReloadScreen:
                    return _this.reloadScreenHandler();
                case MessageType.kMessageMultiplayerGameLauncher:
                    return _this.multiplayerGameLauncherHandle(payloadData);
                case MessageType.kJoinGameInvite:
                    return _this.joinGameInvite(payloadData);
                case MessageType.kMessageLiveClassPopupLauncher:
                    return _this.liveClassClickHandlerInSchoolHours();
                case MessageType.kMessageSearchResultRedirect:
                    return _this.searchRedirect(payloadData);
                case MessageType.kResetShowPopup:
                    return _this.resetShowPopup();
                case MessageType.kMessageOpenHelpPage:
                    return _this.openHelpPage();
                case 'sendDirectEvent':
                    return _this.sendDirectEvent(payloadData);
                case MessageType.KMessageSendLiveClassVisibleEvent:
                    return _this.sendLiveClassVisibleEvent();
                case MessageType.kSkipCoqSurveyCall:
                    return _this.skipCoqSurveyCall();
                case MessageType.kMessageCloseWsPreviewPopup:
                    return _this.closeWsPreviewPopup();
                case MessageType.kResetIsLogin:
                    return _this.resetIsLogin();
                case MessageType.KMessageSpinnerStatus:
                    return _this.handleSpinner(payloadData);
                case MessageType.KMessageSignout:
                    return _this.signOutHandle();
                case MessageType.kKeyboardFocus:
                    return _this.onKeyboardFocus();
                case MessageType.kMessageGoToVerifyEmail:
                    return _this.goToVerifyEmailScreen(payloadData);
                case MessageType.kResetDefaultTabValue:
                    return _this.resetDefaultTabCode();
                case MessageType.kUpdateHash:
                    return _this.updateHash(payloadData);
                case MessageType.kShowGamesUnsupportedScreen:
                    return _this.showGamesUnsupportedScreen();
                case MessageType.kBackgroundMusicHandler:
                    return _this.backgroundMusicHandler(payloadData);
                case MessageType.kPlayPauseBgMusicHandler:
                    return _this.playPauseBgMusic();
                case MessageType.ksetGoldEventPropertiesMessage:
                    return _this.setGoldEventPropertiesHandler(payloadData);
            }
        };
    };
    MessageHandler.prototype.backgroundMusicHandler = function (isEnabled) {
        MessageHandler.actionParams.webAppAudioPlayer(!isEnabled, true);
    };
    MessageHandler.prototype.setGoldEventPropertiesHandler = function (payloadData) {
        setGoldPropertiesInWindow(payloadData);
    };
    MessageHandler.prototype.showGamesUnsupportedScreen = function () {
        MessageHandler.actionParams.setShowGamesUnsupportedScreen(true);
    };
    MessageHandler.prototype.updateHash = function (payload) {
        Store.dispatch({ type: 'SET_PLAYLIST', payload: payload });
        Store.dispatch({ type: 'SET_COURSE_STATUS', payload: payload });
    };
    MessageHandler.prototype.sendDirectEvent = function (payload) {
        if (payload) {
            // @ts-ignore
            trackAnalyticsEvent(payload === null || payload === void 0 ? void 0 : payload.eventName, payload === null || payload === void 0 ? void 0 : payload.props);
        }
    };
    MessageHandler.prototype.liveClassClickHandlerInSchoolHours = function () {
        MessageHandler.setShowLockedGamesPopup(true);
    };
    MessageHandler.prototype.redirectToHash = function (hash) {
        var _a;
        var state = Store.getState();
        document.documentElement.style.fontSize = '10px';
        var subjectRoute = ((_a = state === null || state === void 0 ? void 0 : state.current_course) === null || _a === void 0 ? void 0 : _a.subject_code) == "ela" ? "/reading" : "/math";
        var fromLandingPageOnboarding = getItemFromLocalStorage('viaOnboarding') && getItemFromLocalStorage('from_landing_page');
        // const flutterElement = document.getElementById('sl-flutter-build');
        // if(flutterElement){
        //   // flutterElement.remove();
        // }
        if (fromLandingPageOnboarding && hash.includes('purchase')) {
            hash = "/";
        }
        if (hash) {
            location.hash = hash == "/" ? subjectRoute : hash;
        }
    };
    MessageHandler.prototype.redirectUsingHref = function (path) {
        /// resize react element
        document.documentElement.style.fontSize = '10px';
        if (path) {
            location.href = path;
        }
    };
    MessageHandler.prototype.openNewTab = function (link) {
        setTimeout(function () {
            window.open(link, '_blank');
        });
    };
    MessageHandler.prototype.onFlutterBuildLoaded = function () {
        /// Function to calc total time taken by flutter to render
        if (!sessionStorage.getItem('flutter_build_loading_time')) {
            this.calcFlutterBuildLoadedTime();
        }
        window.flutterScriptLoaded = true;
        WidgetSpinner.hideSpinner();
        GlobalSpinner.show(false);
    };
    MessageHandler.prototype.sendEvent = function (eventName, payloadData) {
        eventName && payloadData && sendEventUsingAvo({ methodName: eventName, params: payloadData });
    };
    MessageHandler.prototype.minimizeHeader = function (payloadData) {
        if (hasAccessToFreemium() && payloadData && payloadData.length) {
            MessageHandler.setIsHeaderBannerTextHidden && MessageHandler.setIsHeaderBannerTextHidden(payloadData[0]);
        }
    };
    MessageHandler.prototype.tileClickHandler = function (payloadData) {
        var actionHandler = getActionHandler(payloadData.elementAction.code);
        var metaDataStore = payloadData.elementAction.metaDataStore;
        var eventParams = payloadData === null || payloadData === void 0 ? void 0 : payloadData.eventParams;
        actionHandler({
            actionData: metaDataStore,
            actionParams: MessageHandler.actionParams,
            payloadData: payloadData,
            eventParams: eventParams
        });
    };
    MessageHandler.prototype.closePopupHandler = function () {
        MessageHandler.setShowt2invitemodal && MessageHandler.setShowt2invitemodal(false);
        localStorage.setItem('showClassInvitePopup', "false");
        this.closeCatexMigrationScreen();
        new ApplicationContext().platformSettings.resetTabCode();
        //make header visible again in case of interest flow
        var header = document.getElementById('home-header-2-5');
        if (header) {
            header.style.removeProperty('pointer-events');
            header.style.removeProperty('opacity');
            header.style.removeProperty('background-color');
        }
    };
    MessageHandler.prototype.closeCatexMigrationScreen = function () {
        if (canShowCatexMigrationScreenValue('get') == 'true') {
            canShowCatexMigrationScreenValue('set', false);
            MessageHandler.setShowCatexMigrationScreen && MessageHandler.setShowCatexMigrationScreen(false);
            try {
                catexMigrationPostAPI();
            }
            catch (error) {
                var errorInfo = {
                    extraMsg: 'Catex Migration Post API failed',
                };
                sendErrorToSentry(error, errorInfo);
            }
        }
    };
    MessageHandler.prototype.blurHeaderHandler = function () {
        var header = document.getElementById('home-header-2-5');
        if (header) {
            header.style.pointerEvents = 'none';
            header.style.opacity = '.5';
            header.style.backgroundColor = 'black';
        }
    };
    MessageHandler.prototype.reloadScreenHandler = function () {
        window.location.reload();
    };
    MessageHandler.prototype.multiplayerGameLauncherHandle = function (payload) {
        return __awaiter(this, void 0, void 0, function () {
            var gameSource, applicationContext, gameModeCode, extraParams, paramsForGameRoom, gameRoomDetailsResp, activeTabCode, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        gameSource = 'Game creation screen';
                        applicationContext = new ApplicationContext();
                        gameModeCode = payload.game_play_mode_code;
                        extraParams = filterExtraParamsForLauncher(typeof (payload.queryParameters) == 'string' ? JSON.parse(payload.queryParameters) : payload.queryParameters);
                        paramsForGameRoom = {
                            game_play_mode_id: payload.game_play_mode_id,
                            invited_by_id: applicationContext.userService.studentUser.id,
                            playzone_assigned: true,
                            players: payload.players,
                            level: payload.level,
                            grade_code: payload.grade_code,
                            topic_id: payload.topic_id,
                            client_id: sessionStorage.getItem('client_id') || "",
                            udrs_item_id: extraParams === null || extraParams === void 0 ? void 0 : extraParams.udrs_item_id
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, CreateGameRoom(paramsForGameRoom, true)];
                    case 2:
                        gameRoomDetailsResp = _a.sent();
                        window.iframeOn = true;
                        activeTabCode = getItemFromLocalStorage("flutter.activeTabCode");
                        gameRoomDetailsResp &&
                            MultiplayerGameLauncher({
                                gameRoomId: gameRoomDetailsResp.game_room.id,
                                gameRoomPlayerId: gameRoomDetailsResp.game_room_player.id,
                                source: gameSource,
                                playzone_assigned: true,
                                click_source: "",
                                active_tab_code: activeTabCode,
                                extra_param_for_launcher: filterExtraParamsForLauncher(typeof (payload.queryParameters) == 'string' ? JSON.parse(payload.queryParameters) : payload.queryParameters)
                            }, this.closeHandlerMPGame);
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        console.log(err_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    MessageHandler.prototype.joinGameInvite = function (payload) {
        return __awaiter(this, void 0, void 0, function () {
            var gameRoomDetailsResp, gameSource, currentPageHash, errorInfo;
            return __generator(this, function (_a) {
                try {
                    gameRoomDetailsResp = payload === null || payload === void 0 ? void 0 : payload.gameRoomDetailsResp;
                    window.iframeOn = true;
                    gameSource = (payload === null || payload === void 0 ? void 0 : payload.gameSource) || "Invite Notification";
                    currentPageHash = window.location.hash.replace('#/', '');
                    gameRoomDetailsResp &&
                        MultiplayerGameLauncher({
                            gameRoomId: gameRoomDetailsResp.game_room.id,
                            gameRoomPlayerId: gameRoomDetailsResp.game_room_player.id,
                            source: gameSource,
                            referrerPage: currentPageHash
                        }, this.closeHandlerMPGame);
                }
                catch (error) {
                    console.error(error);
                    errorInfo = {
                        extraMsg: 'join game invite message handler failed',
                    };
                    sendErrorToSentry(error, errorInfo);
                }
                return [2 /*return*/];
            });
        });
    };
    MessageHandler.prototype.searchRedirect = function (payload) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var subjectName, props;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (payload === null || payload === void 0 ? void 0 : payload.milestone_id) {
                            localStorage.setItem('milestone_id', payload.milestone_id);
                        }
                        if (payload === null || payload === void 0 ? void 0 : payload.lo_id) {
                            localStorage.setItem('learning_objective_id', payload.lo_id);
                        }
                        subjectName = ((_a = payload === null || payload === void 0 ? void 0 : payload.course_code) === null || _a === void 0 ? void 0 : _a.includes(READING_SUBJECT_CODE)) ? READING_SUBJECT_CODE : MATH_SUBJECT_CODE;
                        props = {
                            actionData: { subject_name: subjectName },
                            actionParams: MessageHandler.actionParams
                        };
                        return [4 /*yield*/, setActiveCourse(props, true)];
                    case 1:
                        _b.sent();
                        location.hash = "/topic/".concat(payload.cg_id);
                        return [2 /*return*/];
                }
            });
        });
    };
    MessageHandler.prototype.resetShowPopup = function () {
        new ApplicationContext().platformSettings.setShowPopupValue('');
    };
    MessageHandler.prototype.resetIsLogin = function () {
        new ApplicationContext().platformSettings.setIsLogin(false);
    };
    MessageHandler.prototype.openHelpPage = function () {
        window.open(TEACHER_SUPPORT_PAGE_PATH, '_blank');
    };
    MessageHandler.prototype.sendLiveClassVisibleEvent = function () {
        window.trackAnalyticsEvent('Live Class Tab Available', { 'from': 'Header' });
    };
    MessageHandler.prototype.skipCoqSurveyCall = function () {
        new ApplicationContext().platformSettings.skipCoqSurveyCallApi(true);
    };
    /// dispatched window event which is used to close ws preview popup
    MessageHandler.prototype.closeWsPreviewPopup = function () {
        var myEvent = new CustomEvent("closePreviewPopup"); // created custom event
        window.dispatchEvent(myEvent); // listened it in school-ui service
    };
    MessageHandler.prototype.handleSpinner = function (payload) {
        if (payload.visible) {
            WidgetSpinner.showSpinner();
            GlobalSpinner.show(true);
        }
        else {
            WidgetSpinner.hideSpinner();
            GlobalSpinner.show(false);
        }
    };
    MessageHandler.prototype.signOutHandle = function () {
        localStorage.setItem('from_signout', 'true');
        location.href = '/signout';
    };
    MessageHandler.prototype.onKeyboardFocus = function () {
        var iframeElement = document.getElementById('flutter-onboarding');
        if (iframeElement) {
            var iframeShifted = iframeElement.getBoundingClientRect().y;
            if (iframeShifted < 0) {
                iframeElement.style.top = "".concat(Math.abs(iframeShifted), "px");
            }
            else {
                iframeElement.style.top = '0px';
            }
        }
    };
    MessageHandler.prototype.goToVerifyEmailScreen = function (payloadData) {
        MessageHandler.goToVerifyEmailScreen && MessageHandler.goToVerifyEmailScreen(true, { intent: payloadData.intent, redirect_url: payloadData.redirect_url, show_password: payloadData.show_password, teacher_referral_code: payloadData.teacher_referral_code });
    };
    MessageHandler.prototype.resetDefaultTabCode = function () {
        new ApplicationContext().platformSettings.resetDefaultTabCode();
        deleteCookie(TAB_CODE);
    };
    /// Function to calc total time taken by flutter to render
    MessageHandler.prototype.calcFlutterBuildLoadedTime = function () {
        var initiatedTime = sessionStorage.getItem('flutter_build_initiated') || '';
        var flutterBuildLoadedTime = new Date();
        var totalTimeInSeconds = (flutterBuildLoadedTime.getTime() - new Date(initiatedTime).getTime()) / 1000;
        sessionStorage.setItem('flutter_build_loading_time', "".concat(totalTimeInSeconds, " sec"));
        window.trackAnalyticsEvent('Flutter build loading time', { 'time in sec': "".concat(totalTimeInSeconds) });
    };
    MessageHandler.prototype.handleChildAdd = function () {
        handleAddFromChildProfile();
    };
    return MessageHandler;
}());
export default MessageHandler;
